import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/Layout/Layout"

// Import components
import HeroBanner from "../components/HeroBanner/HeroBanner"
import GridContainer from "../components/layout/GridContainer/GridContainer"
import HTMLWrapper from "../components/HTMLWrapper/HTMLWrapper"
import UserContent from "../components/content-modules/UserContent"

export const query = graphql`
  query ($startDate: Date!) {
    datoCmsMenu {
      ...InsiderMenu
      ...AdventureMenu
    }
    homepage: datoCmsHomePage {
      ...HomePage
    }
    whatsOn: allDatoCmsEvent(
      sort: {order: ASC, fields: eventDate},
      filter: {eventDate: {gte: $startDate}}
    ) {
      ...WhatsOn
    }
    datoCmsSiteConfig {
      whatsOnNoResultsText
      whatsOnDropDownRange
    }
  }
`

const IndexPage = (props) => {
  const whatsOnData = {
    events: props.data.whatsOn.nodes.slice(0, props.data.datoCmsSiteConfig.whatsOnDropDownRange),
    message: props.data.datoCmsSiteConfig.whatsOnNoResultsText
     ? props.data.datoCmsSiteConfig.whatsOnNoResultsText
     : undefined
  }

  const buildHeaderImages = (baseImages) => {
    const processedHeaderImages = []
    for (let i = 0; i < baseImages.length; i++) {
      processedHeaderImages.push({
        sources: [
          baseImages[i].fluid,
          {
            ...props.data.homepage.headerImagesTablet[i].fluid,
            media: `(min-width: 769px)`,
          },
          {
            ...props.data.homepage.headerImagesDesktop[i].fluid,
            media: `(min-width: 1024px)`,
          },
          {
            ...props.data.homepage.headerImagesWidescreen[i].fluid,
            media: `(min-width: 1216px)`,
          },
        ],
        title: baseImages[i].title,
        alt: baseImages[i].alt
      })
    }
    return processedHeaderImages;
  }

  return (
    <>
      <Layout
        metaTitle={props.data.homepage.seo ? props.data.homepage.seo.title : props.data.homepage.title}
        metaDescription={props.data.homepage.seo ? props.data.homepage.seo.description : null}
        metaImage={
          props.data.homepage.seo && props.data.homepage.seo.image ? props.data.homepage.seo.image.url : null
        }
        twitterCard={
          props.data.homepage.seo && props.data.homepage.seo.twitterCard
          ? props.data.homepage.seo.twitterCard
          : null
        }
        pathname="/"
        >
        <HeroBanner
          adventureMenu={props.data.datoCmsMenu.adventureMenu}
          insiderMenu={props.data.datoCmsMenu.insider}
          whatsOn={whatsOnData}
          images={buildHeaderImages(props.data.homepage.headerImages)}
          tabs={true}
          primaryTheme={true}
          isHomePage={true}
        />
        <GridContainer>
          <HTMLWrapper text={props.data.homepage.introductoryText} />
          {props.data.homepage.content.map((contentModule, index) => {
            return (
              <UserContent content={contentModule} key={index}/>
            )
          })}
        </GridContainer>
      </Layout>
    </>
  )
}

export default IndexPage
